import React from "react"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image"
import Slider from "react-slick"

const Container = styled.div`
  margin-top: 60px;

  @media all and (min-width: 992px) {
    margin-top: 0;
  }
  p {
    text-align: justify;
    text-justify: inter-word;
  }

  h3 {
    color: #ffcc99!;
    font-size: 30px;
  }

  h5 {
    color: #ffcc99!;
    font-size: 18px;
  }

  h6 {
    color: white;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.5;
  }
`

const SideContainer = styled.div`
  min-width: 250px;
  position: fixed;
  // display:none
  height: 100%;
  z-index: -3;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background: black;
  padding: 0 15px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media all and (min-width: 992px) {
    z-index: 3;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const ContentContainer = styled.div`
  margin-left: 0;
  @media all and (min-width: 992px) {
    padding: 0px 0px 80px 80px;
    margin-left: 250px !important;
  }
`

const StyledOrderedList = styled.ol`
  list-style-type: none;
  @media screen and (max-width: 767px) {
    display: none;
  }
`

const StyledLink = styled(Link)`
  color: ${props => (props.active ? props.theme.activeText : "darkgray")};
  &:hover {
    color: ${props => props.theme.activeText} !important;
  }
`

const StyledSlider = styled(Slider)`
  width: calc(100% + 40px); // twice the value of the parent's padding
  margin-left: -20px; // -1 * parent's padding

  @media all and (min-width: 992px) {
    width: calc(100% + 160px);
    margin-left: -80px;
  }
`

const StyledImg = styled(Img)`
  height: 100%;
`

const StyledH4 = styled.h4`
  margin-bottom: 0;
`

const BottomNav = styled.div`
  margin: 26px 0;
  span {
    font-size: 14px;
    color: white;
  }
`

const title = "News"

const NewsTemplate = props => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
  }
  const options = {
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        console.log(node)
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        return <img alt={alt} src={url} />
      },
    },
  }
  const { edges } = props.data.allContentfulNews
  const { contentfulNews } = props.data
  let index = findIndexByKey(edges, "title", contentfulNews.title)
  console.log(contentfulNews)
  return (
    <Layout activeMenu={title}>
      <SEO title={title} />
      <Container>
        {/* Data for the all the news, for left sidebar navigation */}
        <SideContainer>
          <StyledOrderedList>
            {edges.map((edge, index) => {
              return (
                <StyledLink
                  key={index}
                  to={`/news/${edge.node.slug}`}
                  active={edge.node.title === contentfulNews.title ? 1 : 0}
                >
                  <StyledH4>{edge.node.title}</StyledH4>
                  <span>{edge.node.publishedDate}</span>
                </StyledLink>
              )
            })}
          </StyledOrderedList>
        </SideContainer>
        {/* Data for the individual news */}
        <ContentContainer>
          <StyledSlider {...settings}>
            {contentfulNews.images.map((image, index) => (
              <StyledImg fluid={image.fluid} alt={contentfulNews.title} />
            ))}
          </StyledSlider>
          <StyledH4>{contentfulNews.title}</StyledH4>
          <p>{contentfulNews.publishedDate}</p>
          {documentToReactComponents(contentfulNews.body.json, options)}
          {edges.length > 1 ? (
            <BottomNav>
              {index === 0 ? (
                <Link to={`/news/${edges[1].node.slug}`}>
                  <span>previous</span>
                </Link>
              ) : (
                <div>
                  {index < edges.length - 1 ? (
                    <Link to={`/news/${edges[index + 1].node.slug}`}>
                      <span>previous /</span>
                    </Link>
                  ) : null}
                  <Link to={`/news/${edges[index - 1].node.slug}`}>
                    <span> next </span>
                  </Link>
                </div>
              )}
            </BottomNav>
          ) : null}
        </ContentContainer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulNews(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      body {
        json
      }
      images {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
    allContentfulNews(sort: { fields: [publishedDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishedDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`

export default NewsTemplate

export function findIndexByKey(array, key, value) {
  for (var i = 0; i < array.length; i++) {
    if (array[i].node[key] === value) {
      return i
    }
  }
  return null
}
